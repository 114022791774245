import "./app.css";

import LoginModal from "./lib/LoginModal.svelte";

(() => {
  window.addEventListener("load", () => {
    const isAuthenticated = !!_WINE_CLUB_API_CONFIG.api.access_token;
    const links = document.querySelectorAll('[href="#wine-club-login"]');

    let modal: LoginModal | undefined;

    links.forEach((link) => {
      link.removeAttribute("href");
      (link as HTMLAnchorElement).style.cursor = "pointer";
      const icon = link.querySelector("i");

      if (isAuthenticated) {
        icon?.classList.replace("fa-user", "fa-user-check");
      } else {
        icon?.classList.replace("fa-user-check", "fa-user");
      }

      link.addEventListener("click", () => {
        if (isAuthenticated) {
          if (import.meta.env.DEV) {
            window.location.replace(_WINE_CLUB_API_CONFIG.logout_url);
          } else {
            window.location.replace(window.location.origin + "/member");
          }
        } else {
          if (_WINE_CLUB_API_CONFIG.pre_login_screen.enabled) {
            if (!modal) {
              const overflow = document.body.style.getPropertyValue("overflow");
              document.body.style.overflow = "hidden";
              modal = new LoginModal({
                target: document.body,
              });
              modal.$set({
                onClose() {
                  modal?.$destroy();
                  modal = undefined;
                  document.body.style.overflow = overflow;
                },
              });
            }
          } else {
            window.location.replace(
              _WINE_CLUB_API_CONFIG.login_url ??
                window.location.origin + "/wp-login.php",
            );
          }
        }
      });
    });
  });
})();
